.subHeader {
  padding: 0.75rem 0;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.team {
  padding: 2rem 0;
}
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  padding: 1rem 1rem 2rem;
}
.teamDescription {
  line-height: 1.4em;
  margin-bottom: 2rem;
  max-width: 640px;
}
.subHeading {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.item {
  align-items: center;
  display: flex;
  padding: 0.5rem 0;
}
.addEmail {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.email {
  flex: 1;
  padding-right: 0.5rem;
}
.label {
  display: block;
  font-size: 0.75rem;
  line-height: 1.4em;
}
.addEmailButton {
  appearance: none;
  background: var(--bgCtaSecondary);
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: var(--colorCtaSecondary);
  cursor: pointer;
  font-weight: 700;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 0 1.5rem;
}
.errorMessage {
  color: var(--redDark);
  font-size: 14px;
}
.removeButton {
  align-items: center;
  appearance: none;
  background: var(--bgCtaSecondary);
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0;
  text-align: center;
  width: 2rem;
}
.removeIcon {
  width: 1.25rem;
}
.hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
  }
  .team {
    padding: 3rem 0;
  }
}
