.input {
  appearance: none;
  background: #fff;
  border: 1px solid var(--grayLight);
  border-radius: 4px;
  box-shadow: 0;
  font-size: 1rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 0 0.75rem;
  width: 100%;
}
.inputError {
  composes: input;
  background: var(--bgCtaSecondary);
  border: 1px solid;
  color: var(--redDark);
}
