.header {
  background: #fff;
  padding: 1.5rem 0;
}
.title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
