.subHeader {
  padding: 0.75rem 0;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.createChallenge {
  padding: 2rem 0;
}
.content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  padding: 1rem 1rem 2rem;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.question {
  margin-bottom: 1rem;
}
.labelHeading {
  display: block;
  font-family: "ICA Rubrik";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}
.label {
  display: block;
  font-size: 0.75rem;
  line-height: 1.4em;
}
.period {
  margin-bottom: 1.5rem;
}
.action {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.createButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-decoration: none;
}

.questionOptions {
  display: flex;
}
.questionLabel {
  padding-right: 1rem;
  width: 60%;
}
.grades {
  width: 40%;
}

@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
  }
  .createChallenge {
    padding: 2rem 0;
  }
  .period {
    display: flex;
    flex-wrap: wrap;
  }
  .date {
    width: 50%;
  }
  .date:first-child {
    padding-right: 0.5rem;
  }
  .date:nth-child(2) {
    padding-left: 0.5rem;
  }
  .grades {
    display: flex;
    flex-wrap: wrap;
  }
  .grade {
    width: 50%;
  }
  .grade:first-child {
    padding-right: 0.5rem;
  }
  .grade:nth-child(2) {
    padding-left: 0.5rem;
  }
  .createButton {
    font-size: 1rem;
  }
}
