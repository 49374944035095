html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: "Ica Text Ny";
  src: url("./fonts/ICATextNy-Regular.eot?#iefix");
  src: url("./fonts/ICATextNy-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ICATextNy-Regular.woff2") format("woff2"),
    url("./fonts/ICATextNy-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Ica Text Ny";
  src: url("./fonts/ICATextNy-Bold.eot?#iefix");
  src: url("./fonts/ICATextNy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ICATextNy-Bold.woff2") format("woff2"),
    url("./fonts/ICATextNy-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Ica Rubrik";
  src: url("./fonts/ICARubrik-Medium.eot?#iefix");
  src: url("./fonts/ICARubrik-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ICARubrik-Medium.woff2") format("woff2"),
    url("./fonts/ICARubrik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Ica Rubrik";
  src: url("./fonts/ICARubrik-Bold.eot?#iefix");
  src: url("./fonts/ICARubrik-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ICARubrik-Bold.woff2") format("woff2"),
    url("./fonts/ICARubrik-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Ica Rubrik";
  src: url("./fonts/ICARubrik-Black.eot?#iefix");
  src: url("./fonts/ICARubrik-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ICARubrik-Black.woff2") format("woff2"),
    url("./fonts/ICARubrik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

:root {
  --bg: #f9f5f5;
  --bgCta: #e13205;
  --bgCtaSecondary: #fcece7;
  --colorCtaSecondary: #cf2e05;
  --heading: #593d32;
  --red: #cf2e05;
  --redDark: #9b0600;
  --redMid: #e3000b;
  --redLight: #e17366;
  --green: #009738;
  --greenLight: #e4efc6;
  --greenConfirm: #008933;
  --limeGreenMid: #94c11a;
  --gray: #868686;
  --grayLight: #bdbbb9;
  --graySpecial: #ededed;
  --text: #3a3a3a;
  --yellowSpecial: #fdf4be;
  --yellowDark: #fdc300;
  --orangeDark: #e84c05;
  --colorOrangeLightest: #fef7f3;
}
body {
  background-color: var(--bg);
  color: var(--text);
  font-family: "Ica Text Ny";
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3 {
  font-family: "ICA Rubrik";
  font-weight: 700;
}
