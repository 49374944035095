.createTeam {
  padding: 2rem 0;
}
.success {
  align-items: center;
  display: flex;
  min-height: 100vh;
  padding: 2rem 0;
}
.content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  padding: 1rem 1rem 2rem;
}
.successContent {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
  padding: 1rem 1rem 2rem;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.successHeading {
  font-size: 2rem;
  line-height: 1.4em;
  margin-bottom: 0.5rem;
}
.successPreamble {
  line-height: 1.4em;
  margin-bottom: 0.25rem;
  max-width: 720px;
  text-align: center;
}
.successPreamble p {
  margin-bottom: 0.5rem;
}
.successButton {
  background: var(--bgCta);
  border-radius: 100px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin: 1rem auto;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  padding: 0.75rem 2rem;
}
.labelHeading {
  display: block;
  font-family: 'ICA Rubrik';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}
.label {
  display: block;
  font-size: 0.75rem;
  line-height: 1.4em;
}
.action {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.createButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-decoration: none;
}
.textarea {
  appearance: none;
  background: #fff;
  border: 1px solid var(--grayLight);
  border-radius: 4px;
  box-shadow: 0;
  font-family: 'Ica Text Ny';
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  width: 100%;
}
.formGroup {
  margin-bottom: 1.5rem;
}
.formHeading {
  display: block;
  font-family: 'ICA Rubrik';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.addEmail {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
}
.email {
  flex: 1;
  padding-right: 0.5rem;
}
.addEmailButton {
  appearance: none;
  background: var(--bgCtaSecondary);
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: var(--colorCtaSecondary);
  cursor: pointer;
  font-weight: 700;
  height: 3rem;
  margin-bottom: 0.5rem;
  padding: 0 1.5rem;
}
.errorMessage {
  color: var(--redDark);
  font-size: 14px;
}
.item {
  align-items: center;
  display: flex;
  padding: 0.5rem 0;
}
.removeButton {
  align-items: center;
  appearance: none;
  background: var(--bgCtaSecondary);
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0;
  text-align: center;
  width: 2rem;
}
.removeIcon {
  width: 1.25rem;
}
.hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.dropdown {
  background: #fff;
  border: 1px solid var(--grayLight);
  border-radius: 4px;
  box-shadow: 0;
  height: 3rem;
  width: 100%;
}
.loadingDepartments {
  font-size: 14px;
  margin-top: 0.5rem;
}

@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
  }
  .createTeam {
    padding: 2rem 0;
  }
  .createButton {
    font-size: 1rem;
  }
}
